
import { ref } from "vue";
import { addNewRole, editRole, getUserRole } from "@/store/api/devices";
import { defineComponent, onMounted, watch } from "@vue/runtime-core";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default defineComponent({
  props: {
    msg: { type: Object, required: true },
  },
  setup(props) {
    const new_role = ref({
      name: "",
    });
    const payloadValue = ref("");
    const bol_value = ref("");
    const loading = ref(false);
    watch(
      () => props.msg,
      (v) => {
        bol_value.value = v.bolean;
        payloadValue.value = v.id;
      }
    );

    const addnew_Role = async (payload) => {
      loading.value = true;
      const resp = await addNewRole(payload);
      console.log(resp);
      loading.value = false;
      document.getElementById("closebutton")?.click();

      Swal.fire({
        position: "center",
        icon: "success",
        title: "Role Has Been Added!",
        showConfirmButton: false,
        timer: 1500,
      });
    };
    const update_roles = async (val) => {
      loading.value = true;
      const res = await editRole(val.id, { name: val.name });
      loading.value = false;
      console.log(res);
      document.getElementById("closebutton")?.click();

      Swal.fire({
        position: "center",
        icon: "success",
        title: "Role Has Been Updated Successfully!",
        showConfirmButton: false,
        timer: 1500,
      });
    };
    return {
      new_role,
      addnew_Role,
      loading,
      bol_value,
      payloadValue,
      update_roles,
    };
  },
});
